<!-- 
   页面  通行组列表 (这里的Table 用了 elemnt-ui)
-->
<template>
  <div id="collect-groupList-page" class="data-list-container dark-layout">
    <vx-card>
      <div class="table_height_container">
        <!-- 操作按钮 -->
        <div class="operate-container mb-3">
          <vs-button size="small" type="relief" class="mr-4" @click="$router.back(-1)">返回</vs-button>
          <vs-button size="small" type="relief" @click="createInout">新增通行组</vs-button>
        </div>
        <!-- 整体表 -->
        <el-table class="table_theme" :data="groupList" ref="groupListTable" v-loading="loading">
          <el-table-column type="index" width="60" label="序号">
            <template slot-scope="scope">
                <span>{{(pageOpt.currentPage - 1) * pageOpt.currentPageSize + scope.$index + 1}}</span>
            </template>
          </el-table-column>
          <el-table-column property="device_name" label="通行组类型" width="150" show-overflow-tooltip>
            <template>{{ groupTypeName }}</template>
          </el-table-column>
          <el-table-column property="group_name" label="通行组名称"  width="200" show-overflow-tooltip></el-table-column>
          <el-table-column property="group_code" label="通行组编码" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="120" header-align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" class="primary" @click="watchData(scope.row)">详情</el-button>
              <el-button type="text" size="small" class="primary" @click="editData(scope.row)">编辑</el-button>
              <el-button type="text" size="small" class="primary" @click="delData(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="bottom mt-5 text-center">
          <el-pagination  class="pagination" layout="total, sizes, prev, pager, next"  prev-text="上一页" next-text="下一页"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageOpt.currentPage"
            :page-sizes="pageOpt.pageSizes"
            :page-size="pageOpt.currentPageSize"
            :pager-count="pageOpt.pageCount"
            :total="pageOpt.totalPageSize"
            :hide-on-single-page="pageOpt.hideSinglePage"
          ></el-pagination>
        </div>
      </div>
    </vx-card>

    <!-- 弹出框 -->
    <groupDialogCURD :dialogFormOpt="dialogFormOpt" :dialogFormData="dialogFormData" :dialogFormVisible="dialogFormVisible"  @handleDiaLogClose="handleDiaLogClose(arguments)"></groupDialogCURD>
  </div>
</template>

<script>
// 导入弹框
import groupDialogCURD from "./GroupDialogCURD";

export default {
  data() {
    return {
      // 选中数据
      selected: [],
      // 渲染数据
      villageName: "",
      villageCode: "",
      groupType: "",
      groupTypeName: "",
      groupList: [],
      buildings: [],
      // 加载状态
      loading: false,
      // 弹出框状态
      dialogFormVisible: false,
      // 弹出框所需数据
      dialogFormData: {},
      // 弹出框所做的操作: CRUD
      dialogFormOpt: "",
      // 当前页
      pageOpt: {
        currentPage: 1,
        currentPageSize: 10,
        totalPageSize: 0,
        hideSinglePage: true,
        pageSizes: [10, 20, 30],
        pageCount: 5
      }
    };
  },
  watch: {
    "$store.state.auth.space.space_code"(val) {
        this.villageCode = val;
        this.getGroupList();
    },
  },
  methods: {
    // 新增设备
    async createInout() {
      // 获取通行组可选的出入口
      let groupChooseInoutList = await this.groupChooseInoutList( this.villageCode );
      if (groupChooseInoutList) {
        // 获取设备
        this.dialogFormOpt = this.$CONST.OPTRATE.C;
        this.dialogFormVisible = true;
        this.dialogFormData = Object.assign({
          groupType: this.groupType,
          groupTypeName: this.groupTypeName,
          villageCode: this.villageCode,
          villageName: this.villageName,
          groupChooseInoutList: groupChooseInoutList
        });
      } else {
        this.$message({ message: "数据正在加载,请稍后打开", type: "warning", duration: 1500, offset: 100});
      }
    },
    // 查看设备
    async watchData(row) {
      // 获取通行组可选的出入口
      let groupChooseInoutList = await this.groupChooseInoutList( this.villageCode );
      // 获取当前通行组详情
      let groupDetail = await this.getGroupDetail(row.group_code, this.villageCode);
      if (groupChooseInoutList) {
        // 获取设备
        this.dialogFormOpt = this.$CONST.OPTRATE.R;
        this.dialogFormVisible = true;
         this.dialogFormData = Object.assign({
          groupType: this.groupType,
          groupTypeName: this.groupTypeName,
          villageCode: this.villageCode,
          villageName: this.villageName,
          groupChooseInoutList: groupChooseInoutList,
          groupDetail: groupDetail
        },row );
      } else {
        this.$message({ message: "数据正在加载,请稍后打开", type: "warning", duration: 1500,offset: 100});
      }
    },
    // 编辑设备
    async editData(row) {
       // 获取通行组可选的出入口
      let groupChooseInoutList = await this.groupChooseInoutList( this.villageCode );
      // 获取当前通行组详情
      let groupDetail = await this.getGroupDetail(row.group_code, this.villageCode);
      if (groupChooseInoutList) {
        // 获取设备
        this.dialogFormOpt = this.$CONST.OPTRATE.U;
        this.dialogFormVisible = true;
        this.dialogFormData = Object.assign({
          groupType: this.groupType,
          groupTypeName: this.groupTypeName,
          villageCode: this.villageCode,
          villageName: this.villageName,
          groupChooseInoutList: groupChooseInoutList,
          groupDetail: groupDetail
        },row );
      } else {
        this.$message({ message: "数据正在加载,请稍后打开", type: "warning", duration: 1500,offset: 100});
      }
    },
    // 删除设备
    async delData(row) {
      // 获取通行组可选的出入口
      let groupChooseInoutList = await this.groupChooseInoutList( this.villageCode );
      // 获取当前通行组详情
      let groupDetail = await this.getGroupDetail(row.group_code, this.villageCode);
      if (groupChooseInoutList) {
        // 获取设备
        this.dialogFormOpt = this.$CONST.OPTRATE.D;
        this.dialogFormVisible = true;
         this.dialogFormData = Object.assign({
          groupType: this.groupType,
          groupTypeName: this.groupTypeName,
          villageCode: this.villageCode,
          villageName: this.villageName,
          groupChooseInoutList: groupChooseInoutList,
          groupDetail: groupDetail
        },row );
      } else {
        this.$message({ message: "数据正在加载,请稍后打开", type: "warning", duration: 1500,offset: 100});
      }
    },
    // 获取通行组可选的出入口 (弹框使用)
    async groupChooseInoutList(villageCode) {
      try {
        let groupChooseInoutList = []
        let result = await this.$apis.ODC("获取通行组可选的出入口", {villageCode: villageCode});
        if (this.$apis.isOk(result)) {
           return groupChooseInoutList = result.data;
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 获取当前通行组详情 (弹框使用)
    async getGroupDetail(groupCode, villageCode) {
      try {
        let result = await this.$apis.ODC("获取通行组详情", {groupCode: groupCode, villageCode: villageCode});
        if (this.$apis.isOk(result)) {
          return result.groupDetail;
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 获取通行组列表
    async getGroupList() {
      this.loading = true;
      try {
        let params = {
          villageCode: this.villageCode,
          groupType: this.groupType,
          pageNum: this.pageOpt.currentPage,
          pageSize: this.pageOpt.currentPageSize
        };
        let result = await this.$apis.ODC("获取通行组列表", params);
        if (this.$apis.isOk(result)) {
          this.groupList = result.data.groupList;
          this.pageOpt.totalPageSize = result.data.count;
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
      this.loading = false;
    },
    // code 切换 设备编码
    deviceDetailType(code) {
      // 匹配16-18位
      let subCode = code.substring(15, 18).toString();
      let codeMap = [
        { code: "001", name: "普通摄像头" },
        { code: "002", name: "人脸抓拍机" },
        { code: "003", name: "监控摄像机" },
        { code: "004", name: "车辆抓拍机" },
        { code: "101", name: "单元门禁机" },
        { code: "102", name: "围墙机" },
        { code: "203", name: "烟感" },
        { code: "204", name: "电表" },
        { code: "205", name: "室外消火栓" },
        { code: "206", name: "电弧" },
        { code: "207", name: "井盖" },
        { code: "208", name: "可燃气" },
        { code: "209", name: "电子围栏" },
        { code: "210", name: "温感" },
        { code: "211", name: "地磁" },
        { code: "212", name: "水压" },
        { code: "213", name: "水质" },
        { code: "214", name: "手环" },
        { code: "215", name: "环境监测设备" },
        { code: "216", name: "地锁" },
        { code: "301", name: "垃圾箱" },
        { code: "302", name: "垃圾桶" },
        { code: "401", name: "电梯" },
        { code: "501", name: "人脸网关" },
        { code: "601", name: "健康一体机" }
      ];
      let matchCode = codeMap.filter(item => item.code == subCode);
      return matchCode.length > 0 ? matchCode[0].name : "未知";
    },
    // 调整单页条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageOpt.currentPageSize = val;
      this.getRoleList();
    },
    // 调整当前页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageOpt.currentPage = val;
      this.getRoleList();
    },
    // 加载动画
    loadAnimation() {
      this.loading = true;
      return new Promise(resolve => {
        setTimeout(() => {
          this.loading = false;
          resolve(true);
        }, 1000);
      });
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = "";
      switch (state) {
        case "success":
          title = "成功";
          color = "success";
          break;
        case "danger":
          title = "错误";
          color = "danger";
          break;
        case "warning":
          title = "警告";
          color = "warning";
          break;
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    // 当子模态框关闭回调
    handleDiaLogClose(isRefresh) {
      this.dialogFormVisible = false;
      if (isRefresh[0]) this.getGroupList();
    }
  },
  created() {
    // 设备类型
    this.groupType = Number(this.$route.params.type);
    this.groupTypeName = this.$route.params.name;
    // 获取小区code
    this.villageCode = this.$store.state.auth.space.space_code || "";
    this.villageName = this.$store.state.auth.space.space_name || "";
    // 请求出入数据
    this.getGroupList();
  },
  components: {
    groupDialogCURD
  }
};
</script>

<style lang="scss" scope="scope">
#collect-groupList-page {
  .table_height_container {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 200px);
  }
  .el-button.is-disabled.el-button--text {
    text-decoration: line-through;
  }
}
</style>
