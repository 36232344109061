<template>
  <el-dialog
    customClass="GroupDialogCURD"
    :visible.sync="isVisible"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-30">{{ dialogTitle }}</h5>
      <vs-divider></vs-divider>
    </span>

    <!-- 弹窗内容 -->
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">

      <el-row :gutter="24">
        <el-col :span="12">
          <!-- 表单认证(点位类型) -->
          <el-form-item label="通行组类型" prop="groupTypeName">
            <el-input v-model="form.groupTypeName"  prefix-icon="el-icon-edit" placeholder="请输入通行组类型" :disabled="true"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <!-- 表单认证(点位名称) -->
          <el-form-item label="通行组名称" prop="groupName">
            <el-input v-model="form.groupName"  prefix-icon="el-icon-edit" placeholder="请输入通行组名称" :disabled="isDisable"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="24" v-if="form.groupType == 1">
        <el-col :span="24">
          <!-- 表单认证(关联-点位) -->
          <el-form-item label="关联-点位" prop="groupSpaces">
            <el-select multiple v-model="form.groupSpaces" placeholder="请关联-点位" class="w-full" :disabled="isDisable">
              <el-option-group v-for="group in groupChooseInoutListOptions" :key="group.label" :label="group.label">
                <el-option v-for="item in group.options" :key="item.value" :label="item.space_name" :value="item.space_code" :disabled="item.disabled"></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>

    <!-- 弹窗底部 -->
    <span slot="footer" class="dialog-footer">
      <vs-divider></vs-divider>
      <vs-button  size="small" type="relief" color="grey"     @click="handleClose" class="mr-5">取 消</vs-button>
      <vs-button  size="small" type="relief" color="danger"   @click="delGroup" class="mr-5 vs-con-loading__container" v-if="isDisable && this.operate == 'DELETE'">删除</vs-button>
      <vs-button  size="small" type="relief" color="primary"  @click="submitForm('ruleForm')" class="vs-con-loading__container"      v-if="!isDisable">确 定</vs-button>
    </span>
  </el-dialog>
</template>

<script>
import TreeSelectGroup from "@/components/el-tree-select/ElTreeSelectGroup.vue";

export default {
  props: ["dialogFormVisible", "dialogFormData", "dialogFormOpt", "deviceType"],
  data() {
    return {
      reFresh: false,
      dialogTitle: "",
      operate: this.dialogFormOpt,
      isVisible: this.dialogFormVisible,
      isDisable: false,
      form: {
        villageCode: "",
        villageName: "",
        groupCode: "",
        groupName: "",
        groupType: 0,
        groupTypeName: "",
        groupSpaces: [],
        extend: null
      },
      groupChooseInoutListOptions: [],
      rules: {
        groupTypeName: [
          { required: true, message: '请输入通行组类型', trigger: 'blur' }
        ],
        groupName: [
          { required: true, message: '请输入通行组名称', trigger: 'blur' }
        ],
        groupSpaces: [
          { required: true, message: '请关联-点位', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {},
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      //console.log("dialogFormData", val);
      this.reFresh = false;

      // 设备所需参数
      this.form = this.decodeParam(val);

      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true;
      });
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.changeNavBarShadow(val);
      this.isVisible = this.dialogFormVisible;
    },
    // 监听父组件传回来的值 - CURD操作值
    dialogFormOpt(val) {
      this.operate = this.dialogFormOpt;
      switch (val) {
        case "CREATE":
          this.dialogTitle = "创建通行组";
          this.isDisable = false;
          break;
        case "READ":
          this.dialogTitle = "查看通行组";
          this.isDisable = true;
          break;
        case "UPDATE":
          this.dialogTitle = "更新通行组";
          this.isDisable = false;
          break;
        case "DELETE":
          this.dialogTitle = "删除通行组";
          this.isDisable = true;
          break;
      }
    }
  },
  methods: {
    // 添加通行组
    async addGroup() {
      try {
        let params = this.encodeParam(this.form);
        let result = await this.$apis.ODC("添加通行组", params);
        if (this.$apis.isOk(result)) {
          this.showNotify("success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 修改通行组
    async updateGroup() {
      try {
        let params = this.encodeParam(this.form);
        let result = await this.$apis.ODC("更新通行组", params);
        if (this.$apis.isOk(result)) {
          this.showNotify("success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 删除通行组
    async delGroup() {
      try {
        let params = {
          groupCode: this.form.groupCode,
          villageCode: this.form.villageCode
        };
        let result = await this.$apis.ODC("删除通行组", params);
        if (this.$apis.isOk(result)) {
          this.showNotify("success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 修改提交参数
    encodeParam(form) {
      return form;
    },
    // 恢复提交参数
    decodeParam(val) {
      let form = JSON.parse(JSON.stringify(this.form));
      
      form.villageCode = val.villageCode;
      form.villageName = val.villageName;

      form.groupCode = val.group_code
      form.groupType = Number(val.groupType);
      form.groupTypeName = val.groupTypeName;
      form.groupName = val.group_name;
      form.groupSpaces= val.groupDetail ? val.groupDetail.group_spaces : []

      // 管理组默认加groupSpaces
      if (Number(val.groupType) == 2) form.groupSpaces = [ val.villageCode]

      // 设备源数据
      let inoutList = val.groupChooseInoutList || {};

      // 设备多选框数据
      let inoutTree = [];
      Object.keys(inoutList).map(item => { 
        let deviceItem = inoutList[item];
        deviceItem.map((val, key) => {
          deviceItem[key]["disabled"] = false;
        })
        inoutTree.push( { "label": item, options: inoutList[item]})
      });
 
      this.groupChooseInoutListOptions = inoutTree;

      return form;
    },
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget;
      this.$refs[formName].validate( async (valid) => {
        if (valid) {
          // 开启loading
          this.$cFun.openDomLoad(this.$vs.loading, el);
           // 判断下操作
          if (this.operate === "CREATE") {
            await this.addGroup();
          } else if (this.operate === "UPDATE") {
            await this.updateGroup();
          }
        } else {
          return false;
        }
      });
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 删除事件
    async delForm() {
      let el = event.currentTarget;
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el, "danger");
      // 提交API删除角色
      await this.delEquipment();
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 关闭弹框回调
    handleClose(done, isRefresh = false) {
      this.isVisible = false;
      if (this.isVisible == false) {
        this.resetForm('ruleForm');
        this.$emit("handleDiaLogClose", isRefresh);
      }
    },
    // 导航栏阴影问题 组件联动 (true, false)
    changeNavBarShadow(val) {
      var dom = document.getElementById("content-area");
      if (dom) {
        if (val) {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .concat(" ")
              .concat("dialog-show-overlay")
          );
        } else {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .replace(" ", "")
              .replace("dialog-show-overlay", "")
          );
        }
      }
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = "";
      switch (state) {
        case "success":
          title = "成功";
          color = "success";
          break;
        case "danger":
          title = "错误";
          color = "danger";
          break;
        case "warning":
          title = "警告";
          color = "warning";
          break;
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.emptyData();
    },
    // 清空数据
    emptyData() {
      this.form = {
        villageCode: "",
        villageName: "",
        groupCode: "",
        groupName: "",
        groupType: 0,
        groupTypeName: "",
        groupSpaces: [],
        extend: null
      }
    }
  },
  created() {},
  destroyed() {
    this.changeNavBarShadow();
  },
  components: {
    TreeSelectGroup
  }
};
</script>

<style lang="scss" scope="scope">
  .GroupDialogCURD {
    width: 50%;
    min-width: 350px;
  }
</style>
